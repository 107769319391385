import React, { useState } from 'react';

import { Alert } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { gql, useMutation, useQuery } from '@apollo/client';

import { startImportProcess } from '../../../../actions/console';

import { NavigationFooter } from '../../../../components/NavigationFooter';
import i18n from '../../../../i18n';
import { LoadingPage } from '../../../../components/LoadingPage';

const QUERY = gql`
  query getUpload($slug: String!) {
    importify {
      upload(slug: $slug) {
        slug
        name,
        columnMappings {
          targetColumns {
            slug
            name
            columns {
              slug
              name
              selected
            }
          }
        }
      }
    }
  }
`

const MUTATION = gql`
  mutation startProcess($slug: String!) {
    startProcessImport(
      slug: $slug
    ) {
      task {
        id
      }
    }
  }
`

export function ReviewUpload({
  slug
}) {
  const [error, setError] = useState(false)
  const [success, setSuccess] = useState(false)
  const [processing, setProcessing] = useState(false)
  const [processingError, setProcessingError] = useState(false)

  const navigate = useNavigate()

  const handleBackClicked = () => {
    navigate('../fields')
  }

  const { data, loading } = useQuery(QUERY, {
    fetchPolicy: 'network-only',
    variables: {
      slug
    }
  });

  const handleSubmit = (event) => {
    event.preventDefault()
    setProcessingError(false)
    setProcessing(true)
    startImportProcess(slug).then(() => {
      setProcessing(false)
      navigate(`../finish`)
    }).catch(e => {
      setProcessing(false)
      setProcessingError(true)
    })
    return false
  }

  const upload = data?.importify?.upload
  
  if (loading) {
    return <LoadingPage />
  }

  if (upload) { 
    return (
      <>
        <form onSubmit={handleSubmit}>
          {processingError && <Alert severity="error">
            {i18n.t('An error occurred during processing the import. Try again later.')}
          </Alert>}
          {success && <Alert severity="success">
            {i18n.t('The import was successfully!')}
          </Alert>} 
          <p>
            {i18n.t('Example how data will mapped')}
          </p>
          <table style={{width: '100%'}}>
            <thead>
              <tr>
                <th>{i18n.t('Target column')}</th>
                <th>{i18n.t('Source column')}</th>
              </tr>
            </thead>
            <tbody>
              {upload.columnMappings.targetColumns.map((targetColumn) => (
                <tr>
                  <td>
                    {targetColumn.name}
                  </td>
                  <td>
                    {targetColumn.columns.find((sourceColumn) => sourceColumn.selected)?.slug ?? '-'}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <NavigationFooter
            saving={processing}
            onFinishClicked={() => {}}
            onBackClicked={handleBackClicked}
          />
        </form>
      </>
    )
  }
}

export function ReviewUploadPage() {
  const { slug } = useParams()
  return <ReviewUpload slug={slug} />
}
