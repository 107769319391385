import localForage from 'localforage'

export async function startImportProcess(slug) {
    // @ts-ignore
    const token = await localForage.getItem('token');
    var data = new FormData()
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/importify/api/upload/${slug}/import`,
      {
        body: data,
        method: 'POST', 
        headers: {
          'Authorization': `JWT ${token}`
        }
      }
    )
    if (!response.ok) {
      throw new Error("Failed to start import")
    }
    return await response.json()
}